import React, {useState} from 'react';
import './App.css';


const App = () => {
  // const [api, setApi] = useState('');
  // const [db, setDB] = useState('');
  // const callAPI = () => {
  //   fetch("http://localhost:9000/test-api")
  //       .then(res => res.text())
  //       .then(res => setApi(res));
  // }
  // callAPI()
  return (
    <div className="App">
      <header className="App-header">
        </header>
    </div>
  );
}

export default App;
